import { AppStore } from "../../stores/appStore";
import { ISurveyQuestionResponder } from "../../types";
import { action, observable } from "mobx";
import React from "react";
import { observer, useLocalStore } from "mobx-react";
import {Modal, Button, List, Tabs} from "antd";
import { UserDeleteOutlined, CheckOutlined, UserAddOutlined, CloseOutlined } from "@ant-design/icons";
import "./contributorsManager.scss";
import {formatPhoneNumber} from "../../common/utils";
import {Spinner} from "../loading/loading";
import {motion} from "framer-motion";

// TODO(ebond): Store first and lastname separately on server
interface IContributor extends ISurveyQuestionResponder {
    firstName: string;
    lastName: string;
    accepted: boolean;
}

const MOCK_CONTRIBUTORS: IContributor[] = [
    {
        created_at: "",
        updated_at: "",
        email: "ethan.s.bond@gmail.com",
        id: 1,
        location: "Raleigh",
        name: "Ethan Bond",
        phone: "9284600962",
        firstName: "Ethan",
        lastName: "Bond",
        accepted: true,
    },
    {
        created_at: "",
        updated_at: "",
        email: "ethan.s.bond@gmail.com",
        id: 2,
        location: "Raleigh",
        name: "Wyatt Bond",
        phone: "9284600962",
        firstName: "Wyatt",
        lastName: "Bond",
        accepted: true,
    },
    {
        created_at: "",
        updated_at: "",
        email: "ethan.s.bond@gmail.com",
        id: 3,
        location: "Raleigh",
        name: "Jason Marmon",
        phone: "9284600962",
        firstName: "Jason",
        lastName: "Marmon",
        accepted: false,
    },
    {
        created_at: "",
        updated_at: "",
        email: "ethan.s.bond@gmail.com",
        id: 4,
        location: "Raleigh",
        name: "Michelle Simas",
        phone: "9284600962",
        firstName: "Michelle",
        lastName: "Simas",
        accepted: false,
    },

    {
        created_at: "",
        updated_at: "",
        email: "ethan.s.bond@gmail.com",
        id: 5,
        location: "Raleigh",
        name: "Rob Bond",
        phone: "9284600962",
        firstName: "Rob",
        lastName: "Bond",
        accepted: true,
    },
];

export class ContributorsManagerStore {
    private appStore: AppStore;
    private circleId: number;
    @observable public contributors: IContributor[];
    @observable public isLoading: boolean = false;

    constructor(appStore: AppStore, circleId: number) {
        this.appStore = appStore;
        this.circleId = circleId;
        this.contributors = [];
        this.loadContributors();
    }

    @action.bound
    public async loadContributors() {
        this.isLoading = true;

        setTimeout(() => {
            this.contributors = MOCK_CONTRIBUTORS;
            this.isLoading = false;
        }, 2000);
    }

    @action.bound
    public removeContributor(id: number) {
        const contributors = this.contributors.filter((c) => c.id !== id);
        this.contributors = contributors;
    }
}

interface IContributorsManagerProps {
    appStore: AppStore;
    circleId: number;
    isOpen: boolean;
    onClose: () => void;
}

export const ContributorsManager: React.FC<IContributorsManagerProps> = observer(
    ({ isOpen, onClose, appStore, circleId }: IContributorsManagerProps) => {
        const store = useLocalStore(() => new ContributorsManagerStore(appStore, circleId));

        const handleRemoveContributor = (id: number) => () => {
            store.removeContributor(id);
        };

        const renderContributor = (contributor: IContributor, i: number) => {
            return (
                <motion.div key={contributor.id} initial={{ opacity: "0", y: `${-20 - (20 * i)}px`}} animate={{ y: "0px", opacity: "1" }}>
                    <List.Item className={"contributor-list-item"}>
                        <div className="basic-info">
                            <div className="name">
                                {contributor.firstName} {contributor.lastName}
                            </div>
                            <div className="contact">
                                {formatPhoneNumber(contributor.phone)} / {contributor.email}
                            </div>
                        </div>
                        <div className="invite-status">{contributor.accepted ? null : "Invited"}</div>
                        <div className="actions">
                            <Button onClick={handleRemoveContributor(contributor.id)} icon={<UserDeleteOutlined />} />
                        </div>
                    </List.Item>
                </motion.div>
            );
        };

        return (
            <Modal
                className={"contributors-manager"}
                visible={isOpen}
                afterClose={onClose}
                onCancel={onClose}
                footer={null}
            >
                <h2>Contributors</h2>

                {store.isLoading ? <Spinner /> : (
                    <div>
                        <List>{store.contributors.map(renderContributor)}</List>
                        <Button icon={<UserAddOutlined />}>Add contributor</Button>
                    </div>
                )}

                <div className="modal-footer-actions">
                    <Button onClick={onClose} icon={<CloseOutlined />}>
                        Cancel
                    </Button>
                    <Button disabled={store.isLoading} type={"primary"} icon={<CheckOutlined />}>
                        Save contributors
                    </Button>
                </div>
            </Modal>
        );
    }
);
