import * as React from "react";
import { AppStore } from "../../stores/appStore";
import { EditionStore, IUniqueContributor } from "../../stores/editionStore";
import { Logo } from "../../common/logo";
import { SideScroller } from "./sideScroller";
import { formatPhoneNumber } from "../../common/utils";
import { Rating } from "./rating";
import styles from "../../views/edition.module.scss";
import { AnimatePresence, motion as M } from "framer-motion";
import { useState } from "react";

interface IProps {
    appStore: AppStore;
    editionStore: EditionStore;
}

const variants = {
    enter: (direction: string) => {
        return {
            scale: 0.8,
            x: direction === "right" ? 1000 : -1000,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        scale: 1,
        opacity: 1,
    },
    exit: (direction: string) => {
        return {
            zIndex: 0,
            x: direction === "left" ? 1000 : -1000,
            opacity: 0,
        };
    },
};

export const ReadingThankYou: React.FC<IProps> = ({ appStore, editionStore }: IProps) => {
    const { height } = appStore.viewportDimensions;
    const [animationDirection, setAnimationDirection] = useState<"left" | "right">("right");
    const [activeAuthorIndex, setActiveAuthorIndex] = useState(0);

    const contributors = Array.from(editionStore.uniqueContributors.values());

    const renderContributor = (c: IUniqueContributor) => (
        <a className={styles.authorCard} key={c.phone} href={`sms:${c.phone}`}>
            <div>
                <strong>{c.name}</strong>
            </div>
            <div>{c.location}</div>
            <div>{formatPhoneNumber(c.phone)}</div>
        </a>
    );

    return (
        <div className={styles.readingThankYou} style={{ height }}>
            <div className={styles.messaging}>
                <h3>Thank you to all {editionStore.numContributors} contributors!</h3>
                <h5>
                    If you saw anything interesting, reach out and start a conversation. Clicking on an author below
                    will get you started!
                </h5>
            </div>
            <div className={styles.authorContainer}>
                <AnimatePresence>
                    <M.div
                        className={styles.authorScrollItem}
                        key={activeAuthorIndex}
                        variants={variants}
                        custom={animationDirection}
                        initial={"enter"}
                        animate={"center"}
                        exit={"exit"}
                        transition={{
                            x: { type: "spring", stiffness: 700, damping: 200 },
                            opacity: { duration: 0.2 },
                        }}
                        drag={"x"}
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={1}
                        onDragEnd={(e, { offset, velocity }) => {
                            const swipe = swipePower(offset.x, velocity.x);

                            if (swipe < -swipeConfidenceThreshold) {
                                if (activeAuthorIndex === contributors.length - 1) {
                                    return;
                                }
                                setAnimationDirection("right");
                                setActiveAuthorIndex(activeAuthorIndex + 1);
                            } else if (swipe > swipeConfidenceThreshold) {
                                if (activeAuthorIndex === 0) {
                                    return;
                                }
                                setAnimationDirection("left");
                                setActiveAuthorIndex(activeAuthorIndex - 1);
                            }
                        }}
                    >
                        {renderContributor(contributors[activeAuthorIndex])}
                    </M.div>
                </AnimatePresence>
            </div>
            <div className={styles.logo}>
                <Logo color={"white"} style={{ width: "50%" }} />
            </div>
            <Rating />
        </div>
    );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};
