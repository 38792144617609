import {action, computed, observable } from "mobx";
import {ResponseFormStore} from "./responseFormStore";
import {
    ApiStore,
} from "./apiStore";
import {AppStore} from "./appStore";
import {RegistrationFormStore} from "./registrationFormStore";
import {IGetSurveyResponsePayload, ISurveySubmissionPayload} from "../types";


export enum SurveyStep {
    Introduction,
    Registration,
    Contribution,
    ThankYou
}

export class SurveyStore {
    @observable public registrationFormStore: RegistrationFormStore;
    @observable public responseFormStore: ResponseFormStore | undefined;
    @observable public step: SurveyStep;
    @observable public isLoading: boolean;
    @observable public isSubmitting: boolean;
    private surveyToken: string;
    private circleId: number;
    private editionId: number;
    private apiStore: ApiStore;
    private appStore: AppStore;

    constructor(appStore: AppStore, circleId: string, editionId: string, surveyToken: string) {
        this.apiStore = appStore.apiStore;
        this.appStore = appStore;
        this.surveyToken = surveyToken;
        this.circleId = parseInt(circleId);
        this.editionId = parseInt(editionId);
        this.isLoading = true;
        this.isSubmitting = false;
        this.registrationFormStore = new RegistrationFormStore(this.surveyToken);
        this.responseFormStore = undefined;
        this.step = SurveyStep.Introduction;
        this.getResponseForm();
    }

    @action.bound
    public async getResponseForm() {
        const response = await this.apiStore.getSurvey(this.circleId, this.editionId, this.surveyToken);
        const json = await response.json();
        const survey: IGetSurveyResponsePayload = json;
        this.responseFormStore = new ResponseFormStore(survey.survey);
        this.isLoading = false;
    }

    @computed
    public get numAnswered() {
        const questions = this.responseFormStore?.questions;
        if (questions === undefined) {
            return 0;
        }

        return questions.map(q => q.isAnswered ? 1 : 0).reduce<number>((acc, val) => acc + val, 0);
    }

    public setStepListener = (step: SurveyStep) => () => {
        this.setStep(step);
    };

    @action.bound
    public setStep(step: SurveyStep) {
        this.step = step;
    }

    @action.bound
    public setIsSubmitting(isSubmitting: boolean) {
        this.isSubmitting = isSubmitting;
    }

    public submit = async (): Promise<boolean> => {
        this.setIsSubmitting(true);

        const responderResponse = this.registrationFormStore.compileResponse();
        const surveyResponse = this.responseFormStore?.compileResponse();

        console.log(surveyResponse);

        if (surveyResponse === undefined) {
            throw new Error("Attempted to submit with empty survey response");
        }

        const payload: ISurveySubmissionPayload = {
            survey_response: {
                survey_question_responses_attributes: surveyResponse,
                survey_responder_attributes: responderResponse,
            },
        };

        const response = await this.apiStore.postSurveySubmission(this.circleId, this.editionId, this.surveyToken, payload);

        this.setIsSubmitting(false);

        return response.ok;
    }
}