import React, { useState } from "react";
import { AppStore } from "../stores/appStore";
import { observer, useLocalStore } from "mobx-react";
import { EditionStore } from "../stores/editionStore";
import { RouteComponentProps } from "react-router-dom";
import { Question } from "../components/edition/question";
import { ReadingThankYou } from "../components/edition/readingThankYou";
import { EditionTitle } from "../components/edition/editionTitle";
import { GlobalLoading } from "../components/loading/loading";
import { Events } from "../components/edition/events";
import styles from "./edition.module.scss";
import { AnimatePresence, motion as M } from "framer-motion";
import { Button } from "../common/common";
import StreamlineIcon from "@streamlinehq/streamline-icons-react";
import { Books } from "@streamlinehq/streamline-bold/lib/content";
import { COLORS } from "../common/utils";

interface IUrlParams {
    circleId: string;
    editionId: string;
    editionToken?: string;
}

interface IProps extends RouteComponentProps<IUrlParams> {
    appStore: AppStore;
    preview: boolean;
}

interface INewsletterProps {
    circleId: number;
    editionId: number;
    appStore: AppStore;
    newsletterToken?: string;
}

enum AnimationDirection {
    UP,
    DOWN,
    RIGHT,
    LEFT,
    IN,
    OUT
}

const variants = {
    enter: (direction: AnimationDirection) => {
        switch (direction) {
            case AnimationDirection.DOWN:
                return {
                    zIndex: 0,
                    y: -1000,
                    scale: 1,
                    opacity: 0,
                };
            case AnimationDirection.UP:
                return {
                    zIndex: 0,
                    y: 1000,
                    opacity: 0,
                    scale: 1,
                };
            case AnimationDirection.OUT:
                return {
                    zIndex: 0,
                    scale: 1.3,
                    y: 0,
                    opacity: 0,
                };
            case AnimationDirection.IN:
                return {
                    zIndex: 0,
                    scale: 0.3,
                    y: 0,
                    opacity: 0,
                };
            default:
                return {
                    zIndex: 0,
                    y: 0,
                    scale: 1,
                    opacity: 0,
                };
        }
    },
    center: {
        zIndex: 1,
        y: 0,
        scale: 1,
        opacity: 1,
    },
    exit: (direction: AnimationDirection) => {
        switch (direction) {
            case AnimationDirection.DOWN:
                return {
                    zIndex: 0,
                    y: 1000,
                    scale: 1,
                    opacity: 0,
                };
            case AnimationDirection.UP:
                return {
                    zIndex: 0,
                    y: -1000,
                    scale: 1,
                    opacity: 0,
                };
            case AnimationDirection.OUT:
                return {
                    zIndex: 0,
                    scale: 0.6,
                    opacity: 0,
                };
            case AnimationDirection.IN:
                return {
                    zIndex: 0,
                    scale: 1.2,
                    opacity: 0,
                };
            default:
                return {
                    zIndex: 0,
                    scale: 0.6,
                    opacity: 0,
                };
        }
    },
};

export const Newsletter: React.FC<INewsletterProps> = observer(
    ({ circleId, editionId, appStore, newsletterToken }: INewsletterProps) => {
        const store = useLocalStore(() => new EditionStore(appStore, circleId, editionId, newsletterToken));
        const [animationDirection, setAnimationDirection] = useState(AnimationDirection.DOWN);
        const [activePageIndex, setActivePageIndex] = useState(0);
        const [activeSlideIndex,  setActiveSlideIndex] = useState(0);

        if (store.isLoading) {
            return <GlobalLoading text={"Loading your newsletter..."} isLoading={store.isLoading} />;
        }

        if (store.edition === undefined) {
            return <h1>Error</h1>;
        }

        const edition = store.edition!;
        const { height } = appStore.viewportDimensions;

        const onExitQuestion = () => {
            setAnimationDirection(AnimationDirection.OUT);
            setActiveSlideIndex(0);
        };

        const onNextSection = () => {
            setAnimationDirection(AnimationDirection.OUT);
            setActiveSlideIndex(0);
            setActivePageIndex(activePageIndex + 1);
        };


        const enterQuestion = (_questionId: number) => () => {
            setAnimationDirection(AnimationDirection.IN);
            setActiveSlideIndex(activeSlideIndex + 1);
        };

        const questionPages = edition.survey.survey_questions.map((q, i) => {
            const numResponses = q.survey_question_responses.filter((r) => !r.hidden && r.response && r.response !== "")
                .length;

            return (
                <div className={styles.questionCoverPage}>
                    <h4 className={styles.questionNumber}>Prompt #{i + 1}</h4>
                    <h1>{q.prompt}</h1>

                    <Button icon={Books.BookFlipPage} className={styles.openQuestionButton}
                            onClick={enterQuestion(q.id)}
                    >
                        Read {numResponses} {numResponses === 1 ? "response" : "responses"}
                    </Button>
                </div>
            );
        });

        const NUM_PRECURSOR_PAGES = 2;

        const pages = [
            <EditionTitle appStore={appStore} editionStore={store} />,
            <Events appStore={appStore} />,
            ...questionPages,
            <ReadingThankYou appStore={appStore} editionStore={store} />,
        ];

        return (
            <div className={styles.edition}>
                <AnimatePresence initial={false} custom={animationDirection}>
                    {activeSlideIndex === 0 ? (
                        <M.div
                            className={styles.questionScrollItem}
                            key={`${activePageIndex}-${activeSlideIndex}`}
                            variants={variants}
                            custom={animationDirection}
                            initial={"enter"}
                            animate={"center"}
                            exit={"exit"}
                            transition={{
                                y: { type: "spring", stiffness: 300, damping: 200 },
                                opacity: { duration: 0.2 },
                            }}
                            drag={"y"}
                            dragConstraints={{ top: 0, bottom: 0 }}
                            dragElastic={1}
                            onDragEnd={(e, { offset, velocity }) => {
                                const swipe = swipePower(offset.y, velocity.y);

                                if (swipe < -swipeConfidenceThreshold) {
                                    if (activePageIndex === pages.length - 1) {
                                        return;
                                    }
                                    setAnimationDirection(AnimationDirection.UP);
                                    setActivePageIndex(activePageIndex + 1);
                                } else if (swipe > swipeConfidenceThreshold) {
                                    if (activePageIndex === 0) {
                                        return;
                                    }
                                    setAnimationDirection(AnimationDirection.DOWN);
                                    setActivePageIndex(activePageIndex - 1);
                                }
                            }}
                        >
                            {pages[activePageIndex]}
                        </M.div>

                    ) : (
                        <M.div
                            className={styles.questionScrollItem}
                            key={`${activePageIndex}-${activeSlideIndex}`}
                            variants={variants}
                            custom={animationDirection}
                            initial={"enter"}
                            animate={"center"}
                            exit={"exit"}
                        >
                            <Question onNextSection={onNextSection} onExit={onExitQuestion} questionAndResponses={edition.survey.survey_questions[activePageIndex - NUM_PRECURSOR_PAGES]} height={height} />
                        </M.div>
                    )}
                </AnimatePresence>
            </div>
        );
    }
);

export const NewsletterWrapper: React.FC<IProps> = observer(({ appStore, match }: IProps) => {
    const { editionToken, editionId, circleId } = match.params;

    return (
        <Newsletter
            appStore={appStore}
            editionId={parseInt(editionId)}
            circleId={parseInt(circleId)}
            newsletterToken={editionToken}
        />
    );
});

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};
