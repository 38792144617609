import React from "react";
import styles from "./submissionThankYou.module.scss";
import {Logo} from "../../common/logo";

export const SubmissionThankYou: React.FC = () => {
    return (
        <div className={styles.thankYou}>
            <div className={styles.directions}>
                <h3>Thank you for contributing!</h3>
                <p style={{ marginBottom: "2rem" }}>
                    This edition will be published on <br /> <strong>August 1</strong>.
                    <br /><br />
                    We'll email you when it's ready.
                </p>
            </div>
            <div className={styles.footer}>
                <Logo color={"white"} style={{ width: "50%"}} />
                <p className={styles.interestCta}>Interested in starting a Slowpost circle of your own?<br />Reach out to Ethan!</p>
            </div>
        </div>
    )
};

