import { Link, RouteComponentProps } from "react-router-dom";
import { AppStore } from "../stores/appStore";
import { observer, useLocalStore } from "mobx-react";
import React from "react";
import { Breadcrumb, Button } from "antd";
import { CircleStore } from "../stores/circleStores";
import { CreateEditionForm } from "../components/createEditionForm/createEditionForm";
import { EditionCard } from "../components/editionCard/editionCard";
import { PlusOutlined } from "@ant-design/icons";
import { ContributorsManager } from "../components/contributorsManager/contributorsManager";
import styles from "./circles.module.scss";
import { motion } from "framer-motion";

const cn = require("classnames");


interface IUrlParams {
    circleId: string;
}

interface IProps extends RouteComponentProps<IUrlParams> {
    appStore: AppStore;
}

export const Circle: React.FC<IProps> = observer(({ match, appStore }: IProps) => {
    const circleId = parseInt(match.params.circleId);
    const store = useLocalStore(() => new CircleStore(circleId, appStore));

    const openCreateDialog = () => {
        store.setIsCreateDialogOpen(true);
    };

    const closeCreateDialog = () => {
        store.setIsCreateDialogOpen(false);
    };

    const closeContributorsDialog = () => {
        store.setIsContributorsDialogOpen(false);
    };

    const openContributorsDialog = () => {
        store.setIsContributorsDialogOpen(true);
    };

    const redirectToEdition = (id: number) => () => {
        appStore.history.push(`./${circleId}/editions/${id}`);
    };

    return (
        <div>
            <CreateEditionForm isOpen={store.isCreateDialogOpen} circleStore={store} onClose={closeCreateDialog} />
            <ContributorsManager
                appStore={appStore}
                circleId={circleId}
                isOpen={store.isContributorsDialogOpen}
                onClose={closeContributorsDialog}
            />
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to={"../"}>Curator</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={"../"}>Your circles</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className={ styles.titleBar }>
                <h1 className={ styles.pageTitle }>{store.circle ? store.circle.name : "Loading..."}</h1>
                <Button onClick={openContributorsDialog}>Contributors</Button>
            </div>
            <div className={cn(styles.pageContent, styles.cardGrid)}>
                <div className={"create-edition-card"} onClick={openCreateDialog}>
                    <PlusOutlined /> Create a new edition
                </div>
                {store.editions.map((e) => (
                    <motion.div key={e.id} initial={{ opacity: "0", y: "-50px" }} animate={{ y: "0px", opacity: "1" }}>
                        <EditionCard onClick={redirectToEdition(e.id)} edition={e} />
                    </motion.div>
                ))}
            </div>
        </div>
    );
});
