import { Link } from "react-router-dom";
import { AppStore } from "../stores/appStore";
import React from "react";
import { ResponsesStore } from "../stores/responsesStore";
import { observer, useLocalStore } from "mobx-react";
import { QuestionAndResponses } from "../components/questionAndResponses/questionAndResponses";
import { Button } from "antd";
import { ISurveyQuestionAndResponses } from "../types";

interface IProps {
    appStore: AppStore;
    circleId: string;
    editionId: string;
    surveyToken?: string;
}

export const Responses: React.FC<IProps> = observer(({ circleId, editionId, appStore, surveyToken }: IProps) => {
    const store = useLocalStore(
        () => new ResponsesStore(appStore, parseInt(circleId), parseInt(editionId), surveyToken)
    );

    if (!surveyToken) {
        return <h4>No survey token given</h4>;
    }

    if (store.responses === undefined) {
        return <div>Loading...</div>;
    }

    const renderQuestionAndResponses = (qandr: ISurveyQuestionAndResponses) => {
        return (
            <QuestionAndResponses
                imageRootUrl={appStore.remoteRoot}
                data={qandr}
                key={qandr.id}
                onHide={store.hideResponses}
                onHighlight={store.hideResponses}
                onShow={store.hideResponses}
            />
        );
    };

    return (
        <div>
            {store.responses?.survey.survey_questions.map(renderQuestionAndResponses)}
            <Link to={`${appStore.urlPath}/preview`}>
                <Button>Preview edition</Button>
            </Link>
        </div>
    );
});
