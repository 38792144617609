import {AppStore} from "./appStore";
import {action, observable} from "mobx";
import {ICircle} from "../types";
import {message} from "antd";

export class CirclesStore {
    private appStore: AppStore;
    @observable circles: ICircle[] | undefined;
    @observable isLoadingPage: boolean;
    @observable isCreatingCircle: boolean = false;
    @observable isCreateDialogOpen: boolean = false;

    constructor(appStore: AppStore) {
        console.log("initializing circlesstore");
        this.appStore = appStore;
        this.circles = undefined;
        this.isLoadingPage = true;

        this.loadCirclesForCurrentUser();
    }

    @action.bound
    public setIsCreateDialogOpen(isCreateDialogOpen: boolean) {
        this.isCreateDialogOpen = isCreateDialogOpen;
    }

    @action.bound
    public setIsCreatingCircle(isCreatingCircle: boolean) {
        this.isCreatingCircle = isCreatingCircle;
    }

    @action.bound
    public async loadCirclesForCurrentUser() {
        this.isLoadingPage = true;

        const response = await this.appStore.apiStore.getCirclesCuratorOf();
        if (!response.ok) {
            message.error("Could not load your circles...");
            console.error(response);
            return;
        }

        const parsed = await response.json();
        this.circles = parsed;
        this.isLoadingPage = false;
    }

    @action.bound
    public async createCircle(circleName: string) {
        this.setIsCreatingCircle(true);

        const response = await this.appStore.apiStore.createCircle(circleName);
        if (!response.ok) {
            message.error("Could not load your circles...");
            console.error(response);
            return;
        }
        const json = await response.json();
        const ok = response.ok;
        console.log(json);

        this.setIsCreatingCircle(false);
        return {ok, circle: json};
    }

}