import {AppStore} from "./appStore";
import {action, computed, observable} from "mobx";
import { ApiStore } from "./apiStore";
import {ICircle} from "../types";

export interface IPartialEdition {
    circle_id: number;
    created_at: string;
    id: number;
    newsletter_token: string | null;
    title: string;
    updated_at: string;
}

export class CircleStore {
    private id: number;
    private appStore: AppStore;
    private apiStore: ApiStore;
    @observable public editions: IPartialEdition[];
    @observable public circle: ICircle | undefined;
    @observable public isCreateDialogOpen: boolean = false;
    @observable public isContributorsDialogOpen: boolean = false;
    @observable private pendingRequests: number = 0;

    constructor(id: number, appStore: AppStore) {
        this.id = id;
        this.appStore = appStore;
        this.apiStore = appStore.apiStore;
        this.editions = [];

        this.getCircle();
        this.getEditions();
    }

    @computed
    public get isLoading() {
        return this.pendingRequests !== 0;
    }

    @action.bound private incrementPendingRequests() { this.pendingRequests = this.pendingRequests + 1; }

    @action.bound private decrementPendingRequests() { this.pendingRequests = this.pendingRequests - 1; }

    @action.bound
    public setIsCreateDialogOpen(isOpen: boolean) {
        this.isCreateDialogOpen = isOpen;
    }

    @action.bound
    public setIsContributorsDialogOpen(isOpen: boolean) {
        this.isContributorsDialogOpen = isOpen;
    }

    @action.bound
    public async getEditions() {
        this.incrementPendingRequests();
        const response = await this.appStore.apiStore.getEditionsForCircle(this.id);
        this.editions = await response.json();
        this.decrementPendingRequests();
    }

    @action.bound
    public async getCircle() {
        this.incrementPendingRequests();
        const response = await this.appStore.apiStore.getCirclesCuratorOf();
        const circle = (await response.json()).find((c: ICircle) => c.id === this.id);
        if (!circle) {
            throw new Error(`Could not find circle with ID ${this.id}`);
        }
        this.circle = circle;
        this.decrementPendingRequests();
    }


    @action.bound
    public async createEdition(title: string, description?: string) {
        console.warn("Description currently does not get sent to server and stored");
        this.incrementPendingRequests();
        const circleId = this.id;
        await this.apiStore.createEdition({circleId, title});
        await this.getEditions();
        this.setIsCreateDialogOpen(false);
        this.decrementPendingRequests();
    }


}