import React from "react";
import styles from "./contributionForm.module.scss";
import {Button} from "antd";
import {QuestionList} from "./questionList";
import {ResponseFormStore} from "../../stores/responseFormStore";
import {observer} from "mobx-react";
import {SurveyStep, SurveyStore} from "../../stores/surveyStore";

interface IProps {
    store: ResponseFormStore | undefined;
    surveyStore: SurveyStore;
}


export const ContributionForm: React.FC<IProps> = observer(({store, surveyStore }: IProps) => {
    if (store === undefined) {
        return <h2>Loading...</h2>;
    }

    const backOrUnfocus = () => {
        if (store.focusedQuestion === undefined) {
            surveyStore.setStep(SurveyStep.Registration);
        } else {
            store.setFocusedQuestion(undefined);
        }
    };

    const unfocus = () => {
        store.setFocusedQuestion(undefined);
    };

    const clearAndUnfocus = () => {
        store.clearAndUnfocus();
    };

    const handleSubmit = async () => {
        surveyStore.submit().then(success => {
            if (success) {
                surveyStore.setStep(SurveyStep.ThankYou);
            } else {
                alert("Oops! Submission failed. Please try again or contact your circle curator for help.\n\nYou must fill in all fields on the registration form and answer at least 1 question to submit.");
            }
        });
    };

    const focusedActions = (
        <div className={styles.stepActions}>
            <Button size={"large"} onClick={clearAndUnfocus}>
                Cancel
            </Button>
            <Button
                type={"primary"}
                size={"large"}
                onClick={unfocus}
                disabled={surveyStore.numAnswered === 0}
            >
                Save
            </Button>
        </div>
    );

    const unfocusedActions = (
        <div className={styles.stepActions}>
            <Button size={"large"} onClick={backOrUnfocus}>Back</Button>
            <Button
                type={"primary"}
                size={"large"}
                onClick={handleSubmit}
                disabled={surveyStore.numAnswered === 0 || surveyStore.isSubmitting}
                loading={surveyStore.isSubmitting}
            >
                {surveyStore.numAnswered === 0 ?
                    "Answer at least 1":
                    `Submit ${surveyStore.numAnswered} ${surveyStore.numAnswered === 1 ? "answer" : "answers"}`
                }
            </Button>
        </div>
    );

    return (
        <div className={styles.contributionForm}>
            <QuestionList store={store}/>
            {store?.focusedQuestion === undefined ? unfocusedActions : focusedActions}
        </div>
    );
});