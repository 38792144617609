import {SurveyStep, SurveyStore} from "../../stores/surveyStore";
import {observer} from "mobx-react";
import React, {ChangeEvent} from "react";
import {QuestionType} from "../../stores/questionStore";
import {Button, Form, Input} from "antd";
import "./registrationForm.scss";
import {IFormItem} from "../../stores/registrationFormStore";


interface IProps {
    surveyStore: SurveyStore;
}

export const RegistrationForm: React.FC<IProps> = observer(({ surveyStore }: IProps) => {
    const store = surveyStore.registrationFormStore;

    const onChangeInput = (key: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        store.setAnswer(key, event.currentTarget.value);
    };

    const renderItemInput = (item: IFormItem) => {
        switch (item.type) {
            case QuestionType.Image:
                return "Upload";
            case QuestionType.Long:
                return <Input.TextArea placeholder={item.placeholder} onChange={onChangeInput(item.id)}/>;
            case QuestionType.Short:
                return <Input placeholder={item.placeholder} onChange={onChangeInput(item.id)}/>;
        }
    };

    return (
        <div className="registration-form">
            <div className="form-container">
                <Form layout={"vertical"}>
                    {store.prompts.map((item: IFormItem) => (
                        <Form.Item name={item.id} label={item.label} required={item.required}>
                            {renderItemInput(item)}
                        </Form.Item>
                    ))}
                </Form>
            </div>
            <div className={"step-actions"}>
                <Button size={"large"} onClick={surveyStore.setStepListener(SurveyStep.Introduction)}>
                    Back
                </Button>
                <Button disabled={!store.isValid} type={"primary"} size={"large"} onClick={surveyStore.setStepListener(SurveyStep.Contribution)}>
                    Next
                </Button>
            </div>
        </div>
    );
});