import React from "react";
import styles from "./app.module.scss";
import { typography } from "./typography";
import {AppStore} from "./stores/appStore";
import {Survey} from "./views/survey";
import {
    RouteComponentProps,
    Switch,
    Route,
    Router,
    Redirect
} from "react-router-dom";
import {Login} from "./views/login";
import {observer, useLocalStore} from "mobx-react";
import {Curator} from "./views/curator";
import { Helmet } from "react-helmet";
import {Newsletter, NewsletterWrapper} from "./views/newsletter";
import { Register } from "./views/register";
import {SwitchWithStrip} from "./common/switchWithStrip";
import { Logout } from "./views/logout";

typography.injectStyles();

/**
 *
 * {
  "routes": [
    {
      "src": "/*",
      "dest": "/public/index.html"
    }
  ]
}
 */


/*
Slowpost is done I think https://slowpost-api.herokuapp.com/

Refer to these tests for create account/login
https://github.com/jtmarmon/slowpost_api/blob/master/spec/requests/auths_spec.rb


These tests for creating circles
https://github.com/jtmarmon/slowpost_api/blob/master/spec/requests/circles_spec.rb

These tests for creating editions, creating the surveys, publishing the surveys,
submitting responses, curating the responses and publishing the newsletter

The thing to pay special attention to is the auth scheme is weird.
Basically every time you make a request, it invalidates the auth token and
sends a new one in the response headers. You need to send “access-token”, “client”,
“expiry”, and “did” headers which are returned with every response from any api call.

The rest of the API is pretty straight forward.
There are two unauthenticated endpoints which is submit survey response
and view newsletter. If you have the survey token, or newsletter token
(both of which are only created when you publish the survey or newsletter, respectively)
you can access those endpoints, or if you’re logged in as the owner.
 */

interface IProps {

}

interface IState {
}


export const App: React.FC<IProps> = observer(() => {
    const appStore = useLocalStore(() => new AppStore());

    const survey = (match: RouteComponentProps<{circleId: string, editionId: string, surveyToken: string}>) => (<Survey appStore={appStore} {...match} />);
    const curator = (match: RouteComponentProps) => (<Curator appStore={appStore} {...match} />);
    const edition = (match: RouteComponentProps<{circleId: string, editionId: string, editionToken: string}>) => (
        <div style={{overflowY: "auto", display: "flex", height: "100%", overflowX: "hidden" }}>
            <NewsletterWrapper preview={false} appStore={appStore} {...match} />
        </div>
    );

    if (appStore.isAttemptingAuth) {
        return <h4>Please wait...</h4>;
    };

    return (
        <Router history={appStore.history}>
            <Helmet>
                <title>slowpost</title>
                <meta name={"description"} content={"The best things take time"} />
            </Helmet>
            <div className={styles.app}>
                <SwitchWithStrip>
                    <Route path={"/logout"}>
                        <Logout appStore={appStore} />
                    </Route>
                    <Route path={"/login"}>
                        <Login appStore={appStore} />
                    </Route>
                    <Route path={"/register"}>
                        <Register appStore={appStore} />
                    </Route>
                    <Route path={"/curator"} component={curator} />
                    <Route path={"/circles/:circleId/editions/:editionId/view/:editionToken"} component={edition} />
                    <Route path={"/survey/:circleId/:editionId/:surveyToken"} component={survey} />
                    <Route path={"/"}>
                        <Redirect to={"/login"} />
                    </Route>
                </SwitchWithStrip>
            </div>
        </Router>
    );
});

