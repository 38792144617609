import * as React from "react";
import {AppStore} from "../stores/appStore";
import { Redirect } from "react-router-dom";

interface ILogoutViewProps {
    appStore: AppStore;
}

export const Logout: React.FC<ILogoutViewProps> = ({ appStore }: ILogoutViewProps) => {
    appStore.signOutUser();

    return (
        <Redirect to={"/"} />
    );
};

