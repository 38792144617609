import styles from "./common.module.scss";
import * as React from "react";
import StreamlineIcon from "@streamlinehq/streamline-icons-react";
import { COLORS } from "./utils";
const cn = require("classnames");

interface ICommonProps {
    className?: string;
    onClick?: () => void;
}

type StreamlineIconDefinition = any;

interface IButtonProps extends ICommonProps {
    text?: string;
    icon?: StreamlineIconDefinition;
    rightIcon?: StreamlineIconDefinition;
    children?: React.ReactChildren | React.ReactText[] | Element | string;
    minimal?: boolean;
    large?: boolean;
    intent?: undefined | "primary" | "danger";
    isIconOutline?: boolean;
    disabled?: boolean;
}

export const Button: React.FC<IButtonProps> = ({
    disabled,
    large = false,
    intent = "primary",
    minimal,
    children,
    icon,
    rightIcon,
    text,
    className,
    onClick,
    isIconOutline = false,
}: IButtonProps) => {
    const isCircle = icon && !children && !text;

    let color = "white";
    switch (intent) {
        case "danger":
            color = minimal ? COLORS.dangerColor : "white";
            break;
        case "primary":
            color = minimal ? COLORS.headingColor : "white";
            break;
    }

    const iconSize = large ? 20 : 16;
    // const iconColor = isIconOutline ? { color: color} : { fill: color};

    return (
        <button
            disabled={disabled}
            className={cn(styles.button, className, styles[intent], {
                [styles.circle]: isCircle,
                [styles.minimal]: minimal,
                [styles.large]: large,
                [styles.disabled]: disabled,
            })}
            {...{ onClick }}
        >
            {icon && (
                <div className={styles.leftIcon}>
                    <StreamlineIcon icon={icon} stroke={isIconOutline ? color : undefined} fill={isIconOutline ? undefined : color} size={iconSize} />
                </div>
            )}
            {text && <span>{text}</span>}
            {children && <span>{children}</span>}
            {rightIcon && (
                <div className={styles.rightIcon}>
                    <StreamlineIcon icon={rightIcon} stroke={isIconOutline ? color : undefined} fill={isIconOutline ? undefined : color} size={iconSize} />
                </div>
            )}
        </button>
    );
};
