import {action, computed, observable} from "mobx";
import {QuestionType} from "./questionStore";
import {ISurveyResponder} from "../types";

export interface IFormItem {
    id: string;
    type: QuestionType;
    label: string;
    required: boolean;
    placeholder: string;
    validator: (value: any) => boolean;
}


const REGISTRATION_PROMPTS: IFormItem[] = [
    {
        id: "name",
        type: QuestionType.Short,
        label: "What's your full name?",
        required: true,
        placeholder: "Bob Loblaw",
        validator: (str: string) => str !== undefined && str.length > 1,
    },
    {
        id: "phone",
        type: QuestionType.Short,
        label: "What's your phone number?",
        required: true,
        placeholder: "1-234-5678",
        validator: (str: string) => str !== undefined && str.length > 1,
    },
    {
        id: "email",
        type: QuestionType.Short,
        label: "What's the best email to reach you?",
        required: true,
        placeholder: "bob@bobloblawslawblog.com",
        validator: (str: string) => str !== undefined && str.length > 1,
    },
    {
        id: "location",
        type: QuestionType.Short,
        label: "Where in the world are you?",
        required: true,
        placeholder: "City, XY",
        validator: (str: string) => str !== undefined && str.length > 1,
    }
];


export class RegistrationFormStore {
    @observable public prompts: IFormItem[] = REGISTRATION_PROMPTS;
    @observable public answers: {[questionId: string]: string} = {};

    private surveyId: string;

    constructor(surveyId: string) {
        this.surveyId = surveyId;
        this.prompts.forEach(prompt => this.answers[prompt.id] = "");
    }

    @action.bound
    public setAnswer(questionId: string, answer: string) {
        this.answers = {
            ...this.answers,
            [questionId]: answer,
        };
    }

    @computed
    public get isValid() {
        const validList = this.prompts.map(prompt => prompt.validator(this.answers[prompt.id]));
        return validList.every(result => result);
    }

    public compileResponse(): ISurveyResponder {
        if (!this.isValid) {
            throw new Error("Attempted to submit without sufficient submitter information");
        }

        return {
            email: this.answers["email"],
            phone: this.answers["phone"],
            name: this.answers["name"],
            location: this.answers["location"],
        }
    }
}

