import React, { useState } from "react";
import { Answer } from "./answer";
import StreamlineIcon from "@streamlinehq/streamline-icons-react";
import { FormValidation } from "@streamlinehq/streamline-bold/lib/interface-essential";
import { Arrows } from "@streamlinehq/streamline-bold/lib/arrows-diagrams";
import { SideScroller } from "./sideScroller";
import { ISurveyQuestionAndResponses } from "../../types";
import styles from "../../views/edition.module.scss";
import { AnimatePresence, motion as M } from "framer-motion";
import { COLORS } from "../../common/utils";
import { Button } from "../../common/common";

const cn = require("classnames");

interface IProps {
    questionAndResponses: ISurveyQuestionAndResponses;
    onExit: () => void;
    onNextSection: () => void;
    height: number;
}

const variants = {
    enter: (direction: number) => {
        return {
            scale: 0.8,
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        scale: 1,
        opacity: 1,
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
        };
    },
};

export const Question: React.FC<IProps> = ({ onNextSection, onExit, height, questionAndResponses }: IProps) => {
    const [[activeViewIndex, direction], setActiveViewIndex] = useState([0, 0]);
    const [scrollY, setScrollY] = useState(0);

    const answerRef = React.createRef<HTMLDivElement>();

    const paginate = (newDirection: number) => {
        setActiveViewIndex([activeViewIndex + newDirection, newDirection]);
    };

    const next = () => paginate(1);
    const prev = () => paginate(-1);

    const { prompt, survey_question_responses } = questionAndResponses;
    const filteredResponses = survey_question_responses.filter((r) => !r.hidden && r.response !== "");

    const updateScrollY = (_e: any, details: any) => {
        const current = answerRef.current;
        if (current) {
            current.scrollTop = current.scrollTop - details.delta.y;
        }
    };

    const exitButton = <Button onClick={onExit} icon={FormValidation.Close} minimal={true} isIconOutline={true} />;
    const lastSlide = activeViewIndex === filteredResponses.length - 1;

    return (
        <div style={{ height: `${height}px` }} className={styles.scrollWindow}>
            <AnimatePresence initial={false} custom={direction}>
                <M.div
                    className={styles.scrollItem}
                    key={activeViewIndex}
                    variants={variants}
                    custom={direction}
                    initial={"enter"}
                    animate={"center"}
                    exit={"exit"}
                    transition={{
                        x: { type: "spring", stiffness: 700, damping: 200 },
                        opacity: { duration: 0.2 },
                    }}
                    drag={"x"}
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    onDrag={updateScrollY}
                    onDragEnd={(e, { offset, velocity }) => {
                        const swipe = swipePower(offset.x, velocity.x);

                        if (swipe < -swipeConfidenceThreshold) {
                            if (activeViewIndex === filteredResponses.length - 1) {
                                return;
                            }
                            paginate(1);
                        } else if (swipe > swipeConfidenceThreshold) {
                            if (activeViewIndex === 0) {
                                return;
                            }
                            paginate(-1);
                        }
                    }}
                >
                    <Answer
                        ref={answerRef}
                        answer={filteredResponses[activeViewIndex]}
                        key={filteredResponses[activeViewIndex].id}
                    />
                </M.div>
            </AnimatePresence>

            <div className={styles.scrollActions}>
                {!lastSlide && exitButton}
                {activeViewIndex !== 0 ? (
                    <Button text={"Previous"} className={styles.scrollAction} onClick={prev} icon={Arrows.ArrowLeft1} />
                ) : (
                    <div />
                )}

                {lastSlide ? (
                    <Button
                        minimal={true}
                        text={"Done!"}
                        className={styles.scrollAction}
                        onClick={onExit}
                        icon={FormValidation.Check1}
                    />
                ) : (
                    <Button text={"Next"} className={styles.scrollAction} onClick={next} icon={Arrows.ArrowRight1} />
                )}
            </div>
        </div>
    );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
};
