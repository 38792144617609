import { format } from "date-fns";

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "d MMM y");
};

export const randomIntFromInterval =(min: number, max: number) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export function formatPhoneNumber(phoneNumberString: string) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

export const COLORS = {
    "backgroundColor": "#FFFAF4",
    "headingColor": "#1D555C",
    "brandColor": "#3B6064",
    "dividerColor": "rgba(0,100,100,0.2)",
    "interactivityColor": "#F5F8FA",
    "ctaColor": "#05c39a",
    "dangerColor": "#F45B69",
};

