import {SurveyStep, SurveyStore} from "../../stores/surveyStore";
import {observer} from "mobx-react";
import React from "react";
import {Button} from "antd";
import "./introduction.scss";


interface IProps {
    surveyStore: SurveyStore;
}

export const Introduction: React.FC<IProps> = observer(({ surveyStore }: IProps) => {
    return (
        <div className={"intro"}>
            <h1 className={"welcome-text"}>
                <img className={"mark"} src={"/images/mark.svg"} alt={"Slowpost logo"}/>
                <img className={"ground"} src={"/images/ground.svg"} alt={"Squiggle"} />
                <span>
                    Hello there!
                </span>
            </h1>
            <div className={"content-area"}>
                <h3>Welcome to slowpost. </h3>
                <h4>Ethan set this up to help the
                    Peanut Gallery stay in touch, have meaningful conversations, and share in the goodness of stronger social
                    connections.</h4>

                <Button
                    className={"next-btn"}
                    type={"primary"}
                    size={"large"}
                    onClick={surveyStore.setStepListener(SurveyStep.Registration)}
                >
                    Get started
                </Button>

            </div>
        </div>
    );
});