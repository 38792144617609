import * as React from "react";
import {EditionStore} from "../../stores/editionStore";
import {observer} from "mobx-react";
import {AppStore} from "../../stores/appStore";
import { MobileOutlined, ArrowDownOutlined } from "@ant-design/icons";
import styles from "../../views/edition.module.scss";
import { AnimatePresence, motion as M } from "framer-motion";

interface IProps {
    appStore: AppStore;
    editionStore: EditionStore;
}

const variants = {
    enter: {
        scale: 0.5,
        y: -100,
        opacity: 0,
    },
    center: {
        scale: 2,
        y: 0,
        opacity: 1,
    },
    exit: {
        scale: 0.5,
        y: -100,
        opacity: 0,
    }
};

export const EditionTitle: React.FC<IProps> = observer(({ appStore, editionStore }: IProps) => {
    const { edition } = editionStore;
    const { height } = appStore.viewportDimensions;

    if (edition === undefined || editionStore.isLoading) {
        return <h2>Loading...</h2>
    }

    return (
        <div className={styles.editionTitle} style={{ height }}>
            <div>
                <h1 className={"medium"}>The Peanut Gallery</h1>
                <h3>{edition.title}</h3>
            </div>
            <AnimatePresence initial={true}>
                <M.div
                    className={styles.heroImageContainer}
                    variants={variants}
                    initial={"enter"}
                    animate={"center"}
                    exit={"exit"}
                    transition={{
                        delay: 0.5,
                        y: { type: "spring", stiffness: 300, damping: 200 },
                        scale: { type: "spring", stiffness: 100, damping: 500 },
                        opacity: { duration: 0.2 },
                    }}
                >
                    <img src="/images/news.svg" alt=""/>
                </M.div>
            </AnimatePresence>
            <div style={{ textAlign: "center" }}>
                <ArrowDownOutlined style={{ marginRight: "10px" }} /> Let's begin
            </div>
        </div>
    );
});

