import * as React from "react";
import { Button, Form, Input, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons/lib";
import { CircleStore } from "../../stores/circleStores";

interface ICreateEditionFormProps {
    circleStore: CircleStore;
    onClose: () => void;
    isOpen: boolean;
}

export const CreateEditionForm: React.FC<ICreateEditionFormProps> = ({
    isOpen,
    circleStore,
    onClose,
}: ICreateEditionFormProps) => {
    const [form] = Form.useForm();

    type FormSubmission = {
        title: string;
        description?: string;
    };

    const handleSubmit = (v: any) => {
        const submission: FormSubmission = v;
        circleStore.createEdition(submission.title, submission.description);
    };

    return (
        <Modal className={"create-edition-form"} visible={isOpen} afterClose={onClose} onCancel={onClose} footer={null}>
            <Form form={form} layout={"vertical"} onFinish={handleSubmit}>
                <Form.Item name={"title"} label={"Edition title"} required={true}>
                    <Input placeholder={"July 2020"} />
                </Form.Item>
                <Form.Item name={"description"} label={"Description or purpose"}>
                    <Input.TextArea
                        placeholder={"To share and learn about how each of us is dealing with current events"}
                    />
                </Form.Item>
                <Button onClick={onClose} style={{ marginRight: "1rem" }}>
                    Cancel
                </Button>
                <Button icon={<PlusOutlined />} type={"primary"} htmlType={"submit"}>
                    Create
                </Button>
            </Form>
        </Modal>
    );
};
