import * as React from "react";
import {useState} from "react";
import {EditionEditorStore, IQuestion} from "../stores/editionEditorStore";
import {QuestionType} from "../stores/questionStore";
import {Button, Form, Input, List, Modal, Select, Checkbox} from "antd";
import {PlusOutlined} from "@ant-design/icons/lib";
import styles from "./editionEditor.module.scss";

interface IEditQuestionForm {
    isOpen: boolean;
    onClose: () => void;
    store: EditionEditorStore;
    initialQuestion?: IQuestion;
}

const EditQuestionForm: React.FC<IEditQuestionForm> = ({ store, onClose, initialQuestion, isOpen }: IEditQuestionForm) => {
    const [form] = Form.useForm();

    const handleClose = () => {
        form.resetFields();
        onClose();
    };

    const handleSubmit = (v: any) => {
        const merged = {id: undefined, ...initialQuestion, ...v};
        store.editQuestion(merged.id, merged.question, merged.type, merged.isImageRequired);
        handleClose();
    };

    return (
        <Modal visible={isOpen} className={"edit-question-form"} onOk={handleClose} footer={null} onCancel={handleClose}>
            <Form initialValues={initialQuestion} form={form} layout={"vertical"} onFinish={handleSubmit}>
                <Form.Item name={"question"} label={"Question/prompt"} required={true}>
                    <Input.TextArea placeholder={"What's one place you want to visit next year? Why there?"} />
                </Form.Item>
                <Form.Item name={"type"} label={"Response type"} required={true}>
                    <Select size={"large"}>
                        <Select.Option value={QuestionType.Short}>Short form</Select.Option>
                        <Select.Option value={QuestionType.Long}>Long form</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name={"isImageRequired"} valuePropName={"checked"}>
                    <Checkbox>Require an image?</Checkbox>
                </Form.Item>
                <Button icon={<PlusOutlined />} type={"primary"} htmlType={"submit"}>
                    {initialQuestion ? "Done" : "Create"}
                </Button>
            </Form>
        </Modal>
    );
};

interface IProps {
    questions: IQuestion[];
    editionEditorStore: EditionEditorStore;
}

export const SurveyEditor: React.FC<IProps> = ({ editionEditorStore, questions }: IProps) => {
    const [isEditQuestionFormOpen, setIsEditQuestionFormOpen] = useState(false);
    const [editQuestionFormValue, setEditQuestionFormValue] = useState<IQuestion | undefined>(undefined);

    const handlePublishSurvey = async () => {
        await editionEditorStore.publishSurvey();
    };

    const handleEditQuestion = (question: IQuestion) => () => {
        setEditQuestionFormValue(question);
        setIsEditQuestionFormOpen(true);
    };

    const handleCloseEditQuestionForm = () => {
        setIsEditQuestionFormOpen(false);
        setEditQuestionFormValue(undefined);
    };

    const handleAddQuestion = () => {
        setEditQuestionFormValue(undefined);
        setIsEditQuestionFormOpen(true);
    };

    return (
        <div className={styles.surveyEditor}>
            <EditQuestionForm
                store={editionEditorStore}
                isOpen={isEditQuestionFormOpen}
                onClose={handleCloseEditQuestionForm}
                initialQuestion={editQuestionFormValue}
            />

            <List
                className={styles.questionsList}
                dataSource={questions}
                renderItem={(question) => (
                    <List.Item style={{ padding: "0.5rem 1rem" }}>
                        <div>
                            {question.question}
                            <Button onClick={handleEditQuestion(question)}>Edit</Button>
                        </div>
                    </List.Item>
                )}
            />

            <Button onClick={handleAddQuestion}>Add question</Button>

            <div className={styles.surveyEditingActions}>
                <Button onClick={editionEditorStore.save}>Save draft</Button>
                <Button onClick={handlePublishSurvey}>Publish survey</Button>
            </div>
        </div>
    );
};
