import {AppStore} from "./appStore";
import {action, observable} from "mobx";
import {IGetSurveyAndResponsesPayload} from "../types";


export class ResponsesStore {
    public appStore: AppStore;
    public circleId: number;
    public editionId: number;
    public surveyToken?: string;
    @observable public isLoading: boolean;
    @observable public responses: IGetSurveyAndResponsesPayload | undefined;

    constructor(appStore: AppStore, circleId: number, editionId: number, surveyToken?: string) {
        this.appStore = appStore;
        this.circleId = circleId;
        this.editionId = editionId;
        this.surveyToken = surveyToken;
        this.isLoading = true;
        this.loadResponses();
    }


    @action.bound
    public loadResponses = async () => {
        this.isLoading = true;

        const response = await this.appStore.apiStore.getSurveySubmissions(this.circleId, this.editionId);
        const json = await response.json();

        this.responses = json;
        this.isLoading = false;
    };

    @action.bound
    public hideResponses = async (responseIds: Set<number>) => {
        this.isLoading = true;
        const response = await this.appStore.apiStore.hideResponses(this.circleId, this.editionId, responseIds);
        const json = await response.json();

        this.responses = json;
        this.isLoading = false;
    }
}