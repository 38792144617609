import {ApiStore, LONG_MAX_CHAR, LONG_MIN_CHAR, SHORT_MAX_CHAR, SHORT_MIN_CHAR} from "./apiStore";
import {action, observable} from "mobx";
import {AppStore} from "./appStore";
import {QuestionType} from "./questionStore";
import {Curator, ICircle, ISurveySubmit} from "../types";

export interface IQuestion {
    question: string;
    type: QuestionType;
    isImageRequired: boolean;
    id?: number;
}


export class EditionEditorStore {
    private circleId: number;
    private editionId: number;
    private apiStore: ApiStore;
    private appStore: AppStore;
    @observable public circle: ICircle | undefined;
    @observable public title: string;
    @observable public questions: IQuestion[];
    @observable public isLoading: boolean;
    @observable public surveyToken: string | undefined;
    @observable public newsletterToken: string | undefined;

    constructor(appStore: AppStore, circleId: string, editionId: string) {
        this.circleId = parseInt(circleId);
        this.editionId = parseInt(editionId);
        this.title = "";
        this.appStore = appStore;
        this.apiStore = appStore.apiStore;
        this.questions = [];
        this.surveyToken = undefined;
        this.newsletterToken = undefined;
        this.isLoading = true;

        this.getCircleInfo();
        this.getEditionContents();
    }

    @action.bound
    private async getCircleInfo() {
        const response = await this.apiStore.getCirclesCuratorOf();
        const json = await response.json();
        const circle = json.find((c: ICircle) => c.id === this.circleId);
        if (!circle) {
            throw new Error(`Could not fetch info for circle with id ${this.circleId}`);
        }

        this.circle = circle;
    }


    @action.bound
    private async getEditionContents() {
        this.isLoading = true;
        const response = await this.apiStore.getEditionPreview(this.circleId, this.editionId);
        const json = await response.json();
        console.log(json);
        this.hydrateFromResponse(json);
        this.isLoading = false;
    }

    @action.bound
    private hydrateFromResponse(response: Curator.GetEdition.ResponsePayload) {
        if (response.survey) {
            this.questions = response.survey.survey_questions.map(q => ({
                question: q.prompt,
                type: q.min_char_len === LONG_MIN_CHAR ? QuestionType.Long : QuestionType.Short,
                id: q.id,
                isImageRequired: q.supports_attachments,
            }));
            this.surveyToken = response.survey.survey_token;
            this.newsletterToken = response.newsletter_token ? response.newsletter_token : undefined;

        }
        this.title = response.title;
    }

    @action.bound
    public setTitle(title: string) {
        this.title = title;
    }

    @action.bound
    public addQuestion(question: string, type: QuestionType, isImageRequired: boolean) {
        this.questions = [...this.questions, { question, type, id: undefined, isImageRequired }];
    }

    @action.bound
    public editQuestion(id: number, question: string, type: QuestionType, isImageRequired: boolean) {
        const indexOfId = this.questions.findIndex(q => q.id === id);

        if (indexOfId === -1) {
            this.addQuestion(question, type, isImageRequired);
            return;
        }

        const newQuestions = [...this.questions];
        newQuestions.splice(indexOfId, 1, {question, id, type, isImageRequired});
        this.questions = newQuestions;
    }

    @action.bound
    public removeQuestion(atIndex: number) {
        const newQuestions = [...this.questions];
        newQuestions.splice(atIndex, 1);
        this.questions = newQuestions;
    }

    private getSurvey = () => {
        console.log(this.questions);
        const survey: ISurveySubmit = {survey_questions_attributes: this.questions.map(q => ({
                max_char_len: q.type === QuestionType.Long ? LONG_MAX_CHAR : SHORT_MAX_CHAR,
                min_char_len: q.type === QuestionType.Long ? LONG_MIN_CHAR : SHORT_MIN_CHAR,
                prompt: q.question,
                supports_attachments: q.isImageRequired,
                id: q.id,
            }))};

        console.log("submitting", survey);
        return survey;
    };

    public save = async () => {
        await this.apiStore.putSurvey(this.circleId, this.editionId, {
            survey: this.getSurvey(),
            title: this.title,
        });
    };

    @action.bound
    private setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    @action.bound
    private setSurveyToken = (surveyToken: string) => {
        this.surveyToken = surveyToken;
    };

    @action.bound
    private setNewsletterToken = (editionToken: string) => {
        this.newsletterToken = editionToken;
    };

    public publishNewsletter = async () => {
        this.setIsLoading(true);

        const response = await this.apiStore.publishNewsletter(this.circleId, this.editionId);
        const json = await response.json();
        console.log("publish responses", json);


        this.setNewsletterToken(json.newsletter_token);
        this.setIsLoading(false);

        return json.token;
    };

    public publishSurvey = async () => {
        this.setIsLoading(true);

        const response = await this.apiStore.publishSurvey(this.circleId, this.editionId);
        const json = await response.json();

        this.setSurveyToken(json.token);
        this.setIsLoading(false);

        return json.token;
    }

}