import {action, computed, observable} from "mobx";
import {ApiStore, } from "./apiStore";
import Cookies from "universal-cookie";
import { createBrowserHistory } from "history";
import {Authorization} from "../types";

export interface ISignedInUser extends Authorization.SignInUser.ResponsePayload {
}

export const IMAGE_URL_ROOT = "https://slowpost-api.herokuapp.com";

export class UiStore {
    @observable private pendingRequests: number;

    constructor(appStore: AppStore) {
        this.pendingRequests = 0;
    }

    @action.bound
    public incrementPendingRequests() {
        this.pendingRequests = this.pendingRequests + 1;
    }

    @action.bound
    public decrementPendingRequests() {
        this.pendingRequests = this.pendingRequests - 1;
    }

    @computed
    public get isLoading() {
        return this.pendingRequests > 0;
    }
}

export class AppStore {
    @observable public apiStore: ApiStore;
    public uiStore: UiStore;
    @observable public currentUser: ISignedInUser | undefined | null;
    @observable public isAttemptingAuth: boolean;
    @observable public viewportDimensions: {width: number, height: number};
    public remoteRoot: string;
    private cookieContext: Cookies;
    public history = createBrowserHistory();

    constructor() {
        this.cookieContext = new Cookies();

        this.uiStore = new UiStore(this);

        const remote = "https://slowpost-api.herokuapp.com";
        const local = "http://localhost:3030";

        const url = remote;
        this.remoteRoot = url;

        this.apiStore = new ApiStore(url, this.cookieContext, {
            start: this.uiStore.incrementPendingRequests,
            end: this.uiStore.decrementPendingRequests,
        });

        this.isAttemptingAuth = true;
        this.viewportDimensions = {width: window.innerWidth, height: window.innerHeight };


        this.addEventListeners();
        this.updateSignInStateFromCookies();

    }

    public getImageUrl(imageSlug: string) {
        return `${this.remoteRoot}${imageSlug}`;
    }

    @action.bound
    private updateViewportDimensions() {
        this.viewportDimensions = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    private addEventListeners() {
        window.addEventListener("resize", this.updateViewportDimensions);
    }

    @computed
    public get urlPath() {
        return this.history.location.pathname;
    }

    @action.bound
    private async updateSignInStateFromCookies() {
        const userFromCookie = await this.apiStore.attemptSignInFromCookie();
        if (userFromCookie) {
            console.log("setting current user");
            this.currentUser = {
                email: "cookie",
                id: 0,
                allow_password_change: false,
                name: "Cookie",
                provider: "email",
                uid: userFromCookie.uid,
                image: null,
                nickname: "Cookie",
            };
        }
        this.isAttemptingAuth = false;
    }

    @action.bound
    public signOutUser() {
        this.currentUser = null;
        this.apiStore.clearUserSession();
    }

    @action.bound
    public async signInUser({email, password}: { email: string, password: string }) {
        const response = await this.apiStore.signInUser({email, password});
        if (response.ok) {
            this.currentUser = (await response.json()).data;
            return { okay: true, message: undefined };
        } else {
            return { okay: false, message: response.statusText};
        }
    }

    @action.bound
    public async registerUser(payload: Authorization.RegisterUser.RequestPayload) {
        const response = await this.apiStore.registerUser(payload);
        console.log(await response.json());
        // this.currentUser = (await response.json()).data;
    }

    @action.bound
    public async createEdition(circleId: number, title: string) {
        if (this.currentUser == null) {
            throw new Error("User is not signed in, cannot create a new edition");
        }

        const response = await this.apiStore.createEdition({circleId, title});
        const {ok} = response;
        const json = await response.json();
        const {id} = json;

        return {ok, id, circleId};
    }
}