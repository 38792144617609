import * as React from "react";
import {Redirect, Switch} from "react-router-dom";

/***
 * react-router-dom <Switch> component that strips trailing slashes from urls to
 * ensure no double // caused by nested and relative routes
 * https://github.com/ReactTraining/react-router/issues/4841#issuecomment-500706719
 * @param children
 * @constructor
 */

interface IProps {
    children: JSX.Element[] | JSX.Element;
}

export const SwitchWithStrip: React.FC<IProps> = ({ children }: IProps) => {
    const pathname = window.location.pathname;
    console.log("re rendering switch");
    return (
        <Switch>
            {
                pathname!== "/" && (/\/$/).test(pathname) &&
                <Redirect to={pathname.slice(0, -1)} />
            }
            {children}
        </Switch>
    );
};
