import {action, computed, observable} from "mobx";
import {LONG_MIN_CHAR} from "./apiStore";
import {ISurveyQuestion} from "../types";

export enum QuestionType {
    Short = "SHORT",
    Long = "LONG",
    Image = "IMAGE",
}

export class QuestionStore {
    public type: QuestionType;
    @observable public answer: string | undefined;
    @observable public file: File | undefined;

    public prompt: string;
    public minLength: number;
    public maxLength: number;
    public supportsAttachments: boolean;
    public id: number;

    constructor(question: ISurveyQuestion) {
        const { id, max_char_len, min_char_len, supports_attachments, prompt } = question;

        if (!id) {
            throw new Error(`Received a question with no ID "${prompt}"`);
        }

        this.id = id;
        this.prompt = prompt;
        this.maxLength = max_char_len;
        this.minLength = min_char_len;
        this.supportsAttachments = supports_attachments;
        this.type = this.getQuestionType();

        this.answer = undefined;
    }

    private getQuestionType() {
        let type = QuestionType.Short;

        if (this.minLength >= LONG_MIN_CHAR) {
            type = QuestionType.Long;
        }

        if (this.supportsAttachments) {
            type = QuestionType.Image;
        }

        return type;
    }

    @computed
    public get isAnswered() {
        if (this.supportsAttachments) {
            return this.file !== undefined;
        }
        return this.answer !== undefined && this.answer !== "";
    }

    @action.bound
    public setAnswer(answer: string | undefined) {
        this.answer = answer;
    }

    @action.bound
    public setFile(file: File | undefined) {
        this.file = file;
    }
}