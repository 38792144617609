import React, { useState } from "react";
import { AppStore } from "../stores/appStore";
import { Button, Input, Form } from "antd";
import { Link } from "react-router-dom";
import { Spinner } from "../components/loading/loading";
import styles from "./register.module.scss";

interface IProps {
    appStore: AppStore;
}

export const Register: React.FC<IProps> = ({ appStore }: IProps) => {
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    type FormSubmission = {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        passwordConfirm: string;
    };

    const attemptRegister = async (registration: FormSubmission) => {
        setIsLoading(true);

        const { email, firstName, lastName, password, passwordConfirm } = registration;

        await appStore.registerUser({
            email,
            password,
            password_confirmation: passwordConfirm,
            confirm_success_url: "www.google.com",
        });
        setIsLoading(false);
    };

    const handleSubmit = (v: any) => {
        const submission: FormSubmission = v;
        attemptRegister(submission);
    };

    return (
        <div className={styles.register}>
            <h1>Become a curator</h1>
            {isLoading ? (
                <div className={styles.registerPanel}>
                    <Spinner />
                </div>
            ) : (
                <div className={styles.registerPanel}>
                    <Form form={form} layout={"vertical"} onFinish={handleSubmit}>
                        <Form.Item
                            name={"firstName"}
                            label={"First name"}
                            rules={[
                                {
                                    required: true,
                                    message: "Other users in your circle will need this to identify you",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={"lastName"}
                            label={"Last name"}
                            rules={[
                                {
                                    required: true,
                                    message: "Other users in your circle will need this to identify you",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={"email"}
                            label={"Email address"}
                            rules={[
                                {
                                    required: true,
                                    message: "This is required in case we need to get in touch with you",
                                },
                            ]}
                        >
                            <Input placeholder={"your@email.com"} />
                        </Form.Item>
                        <Form.Item
                            name={"password"}
                            label={"Password"}
                            rules={[
                                {
                                    required: true,
                                    message: "Pick a secure password, and make sure it matches the next field exactly",
                                },
                            ]}
                        >
                            <Input placeholder={"hunter2"} type={"password"} />
                        </Form.Item>
                        <Form.Item
                            validateTrigger={"blur"}
                            name={"passwordConfirm"}
                            label={"Confirm password"}
                            rules={[
                                {
                                    required: true,
                                    message: "This should exactly match the very secure password in the previous field",
                                },
                            ]}
                        >
                            <Input placeholder={"hunter2"} type={"password"} />
                        </Form.Item>
                        <Button type={"primary"} size={"large"} className={"btn-fill"} htmlType={"submit"}>
                            Create account
                        </Button>
                    </Form>
                    <Link to={"/login"}>
                        Already have an account? <a>Log in!</a>
                    </Link>
                </div>
            )}
        </div>
    );
};
