import * as React from "react";
import styles from "./layout.module.scss";
const cn = require("classnames");

interface IDefaultProps {
    className?: string;
    style?: React.CSSProperties;
}

type FlexBehavior = "flex-start" | "flex-end" | "space-between" | "space-around" | "center" | "stretch";

interface IContainerProps {
    children: JSX.Element | Array<JSX.Element | null> | null;
    horizontal?: FlexBehavior;
    vertical?: FlexBehavior;
}

interface IRowProps extends IDefaultProps, IContainerProps {
}

export const Row: React.FC<IRowProps> = ({ horizontal, vertical, children, className, style }: IRowProps) => {
    return (
        <div className={cn(styles.rowComponent, className)} style={{alignItems: horizontal, justifyContent: vertical, ...style}}>
            {children}
        </div>
    );
};

interface IColumnProps extends IDefaultProps, IContainerProps {
}

export const Column: React.FC<IColumnProps> = ({ horizontal, vertical, children, className, style }: IColumnProps) => {
    return (
        <div className={cn(styles.columnComponent, className)} style={{alignItems: vertical, justifyContent: horizontal, ...style}}>
            {children}
        </div>
    );
};
