import * as React from "react";
import styles from "./loading.module.scss";

import { Logo } from "../../common/logo";
import { motion } from "framer-motion";
import { COLORS } from "../../common/utils";

const cn = require("classnames");

interface IProps {
    isLoading: boolean;
    text?: string;
}

export const GlobalLoading: React.FC<IProps> = ({ text, isLoading }: IProps) => {
    return (
        <div className={styles.globalLoading}>
            <motion.div
                style={{ width: "100%" }}
                animate={{
                    x: ["-10%", "0%", "10%", "15%", "65%", "66%", "100%"],
                }}
                transition={{
                    from: "-10%",
                    loop: Infinity,
                    repeatDelay: 4,
                    default: {
                        duration: 5,
                    },
                }}
            >
                <Logo width={"100px"} color={COLORS.brandColor} withText={false} />
            </motion.div>

            <motion.div animate={{ opacity: [0.5, 1] }} transition={{ yoyo: Infinity, repeatDelay: 0.5 }}>
                <h3 style={{ marginTop: "1rem" }}>{text ? text : "Loading..."}</h3>
            </motion.div>
        </div>
    );
};

export interface ISpinnerProps {
    size?: string;
    className?: string;
    style?: React.CSSProperties;
    text?: string;
    color?: string;
}

 export const Spinner: React.FC<ISpinnerProps> = ({
    color = COLORS.brandColor,
    text,
    size = "50px",
    className,
    style,
}: ISpinnerProps) => {
    return (
        <div className={cn(styles.spinner, className)} style={{ minHeight: size, minWidth: size, ...style }}>
            <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1">
                <g
                    id="Artboard"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <g
                        stroke={color}
                        id="Antu_draw-spiral"
                        transform="translate(46.078720, 55.175176) rotate(-522.000000) translate(-46.078720, -55.175176) translate(14.578720, 26.175176)"
                        strokeWidth="5.05944444"
                    >
                        <path
                            d="M22.5283333,33.0166667 C20.5255556,29.0422222 23.0922222,24.4144444 26.9577778,22.8277778 C32.4138889,20.5877778 38.4455556,24.0722222 40.3627778,29.3844444 C42.8633333,36.3105556 38.4416667,43.7694444 31.675,46.0055556 C23.2866667,48.7822222 14.385,43.4077778 11.8377778,35.1905556 C8.78111111,25.34 15.1122222,14.9955556 24.78,12.1372222 C36.0888889,8.79277778 47.8877778,16.0844444 51.0494444,27.2066667 C54.6816667,39.9738889 46.4294444,53.2272222 33.8488889,56.6922222 C19.6233333,60.62 4.91555556,51.3994444 1.14722222,37.3644444 C-3.06444444,21.6922222 7.11666667,5.51444444 22.6022222,1.44666667 C39.7405556,-3.05666667 57.3688889,8.08888889 61.7244444,25.0133333 C63.6261111,32.3905556 62.9416667,40.3161111 59.8811111,47.2888889"
                            id="Path"
                        ></path>
                    </g>
                </g>
            </svg>

            {text ? <div style={{ marginLeft: `calc(${size} + 1rem)` }}>{text}</div> : null}
        </div>
    );
};
