import {action, observable} from "mobx";
import { QuestionStore } from "./questionStore";
import {ISurveyAnswerSubmission, ISurveyResponse} from "../types";


export class ResponseFormStore {
    @observable questions: QuestionStore[] | undefined;
    @observable focusedQuestion: QuestionStore | undefined;
    private survey: ISurveyResponse;

    constructor(survey: ISurveyResponse) {
        this.survey = survey;
        this.questions = survey.survey_questions.map((q) => {
            return new QuestionStore(q);
        });
    }

    public compileResponse = () => {
        if (this.questions === undefined) {
            return;
        }

        const surveySubmission: Array<ISurveyAnswerSubmission> = this.questions.map((q) => {
            console.log(q, q.file);
            return {
                survey_question_id: q.id,
                response: q.answer ? q.answer : "",
                image: q.file ? q.file : undefined,
            }
        });

        return surveySubmission;
    };

    @action.bound
    private setQuestions(questions: QuestionStore[]) {
        this.questions = questions;
    }

    @action.bound
    public clearAndUnfocus() {
        if (this.focusedQuestion === undefined) {
            return;
        }

        this.focusedQuestion.setAnswer(undefined);
        this.focusedQuestion = undefined;
    }

    @action.bound
    public setFocusedQuestion(questionId: number | undefined) {
        if (this.questions === undefined) {
            return;
        }
        this.focusedQuestion = this.questions.find(q => q.id === questionId);
    }
}