import {CirclesStore} from "../../stores/circlesStore";
import React from "react";
import {observer} from "mobx-react";
import {Button, Form, Input, Select, Spin} from "antd";
import {PlusOutlined} from "@ant-design/icons/lib";

interface ICreateCircleFormProps {
    circlesStore: CirclesStore;
    onClose: () => void;
}

export const CreateCircleForm: React.FC<ICreateCircleFormProps> = observer(
    ({ circlesStore, onClose }: ICreateCircleFormProps) => {
        const [form] = Form.useForm();

        if (circlesStore.isCreatingCircle) {
            return <Spin size={"large"} />;
        }

        const handleCreateNewCircle = async (submission: FormSubmission) => {
            circlesStore
                .createCircle(submission.name)
                .then(() => onClose())
                .then(circlesStore.loadCirclesForCurrentUser);
        };

        enum CirclePurpose {
            PROFESSIONAL = "PROFESSIONAL",
            IMMEDIATE_FAMILY = "IMMEDIATE_FAMILY",
            EXTENDED_FAMILY = "EXTENDED_FAMILY",
            FRIENDS = "FRIENDS",
            ACQUAINTANCES = "ACQUAINTANCES",
            NEIGHBORHOOD = "NEIGHBORHOOD",
        }

        type FormSubmission = {
            name: string;
            frequency: string;
            purpose: CirclePurpose;
        };

        const handleSubmit = (v: any) => {
            const submission: FormSubmission = v;
            handleCreateNewCircle(submission);
        };

        return (
            <div className="create-circle-form">
                <Form form={form} onFinish={handleSubmit} layout={"vertical"}>
                    <Form.Item name={"name"} label={"Circle name"} rules={[{ required: true }]}>
                        <Input placeholder={"The Smith Family"} />
                    </Form.Item>
                    <Form.Item name={"frequency"} label={"Frequency of posts"} rules={[{ required: true }]}>
                        <Select size={"large"}>
                            <Select.Option value={"weekly"}>Every week</Select.Option>
                            <Select.Option value={"biweekly"}>Every other week</Select.Option>
                            <Select.Option value={"monthly"}>Every month</Select.Option>
                            <Select.Option value={"bimonthly"}>Every other month</Select.Option>
                            <Select.Option value={"quarterly"}>Every 3 months</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={"purpose"} label={"What best describes this group?"} rules={[{ required: true }]}>
                        <Select size={"large"}>
                            <Select.Option value={CirclePurpose.IMMEDIATE_FAMILY}>Immediate family</Select.Option>
                            <Select.Option value={CirclePurpose.EXTENDED_FAMILY}>Extended family</Select.Option>
                            <Select.Option value={CirclePurpose.FRIENDS}>Friends</Select.Option>
                            <Select.Option value={CirclePurpose.PROFESSIONAL}>Professional/industry</Select.Option>
                            <Select.Option value={CirclePurpose.ACQUAINTANCES}>Acquaintances</Select.Option>
                            <Select.Option value={CirclePurpose.NEIGHBORHOOD}>Neighborhood or building</Select.Option>
                        </Select>
                    </Form.Item>
                    <Button onClick={onClose} style={{ marginRight: "1rem"}}>
                        Cancel
                    </Button>
                    <Button icon={<PlusOutlined />} type={"primary"} htmlType={"submit"}>
                        Create
                    </Button>
                </Form>
            </div>
        );
    }
);

