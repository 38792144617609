import * as React from "react";
import {ISurveyQuestionResponder} from "../../types";

interface IProps {
    author: ISurveyQuestionResponder;
    className?: string;
    onClick?: () => void;
}

export const Author: React.FC<IProps> = ({ author, className, onClick}: IProps) => {
    return (
        <div className={"author"} {...{ onClick, className }}>
            {author.name} in {author.location}
        </div>
    );
};
