import * as React from "react";
import {IPartialEdition} from "../../stores/circleStores";
import "./editionCard.scss";
import {formatDistanceToNow} from "date-fns";
const cn = require("classnames");

interface IEditionCardProps {
    edition: IPartialEdition;
    onClick?: () => void;
}

export const EditionCard: React.FC<IEditionCardProps> = ({ edition, onClick}: IEditionCardProps) => {
    return (
        <div className={cn("edition-card", {["interactive"]: onClick})} onClick={onClick}>
            <div className="title">{edition.title}</div>
            <div className="updated-at">
                Updated {formatDistanceToNow(new Date(edition.updated_at))} ago
            </div>
        </div>
    );
};

