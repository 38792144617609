import React from "react";
import { Breadcrumb, Modal } from "antd";
import { observer, useLocalStore } from "mobx-react";
import { AppStore } from "../stores/appStore";
import { CirclesStore } from "../stores/circlesStore";
import { PlusOutlined } from "@ant-design/icons";
import { CircleCard } from "../components/circleCard/circleCard";
import { CreateCircleForm } from "../components/createCircleForm/createCircleForm";

import { motion } from "framer-motion";

import styles from "./circles.module.scss";
import { toJS } from "mobx";

interface IProps {
    appStore: AppStore;
}

export const Circles: React.FC<IProps> = observer(({ appStore }: IProps) => {
    const store = useLocalStore(() => new CirclesStore(appStore));

    const closeModal = () => store.setIsCreateDialogOpen(false);
    const openModal = () => store.setIsCreateDialogOpen(true);

    const redirectToCircle = (id: number) => () => {
        // Why does this need ./circles/ in it?!
        appStore.history.push(`./circles/${id}`);
    };

    if (store.circles === undefined) {
        return <div className={styles.circles}>Loading...</div>;
    }


    return (
        <div className={styles.circles}>
            <Modal
                visible={store.isCreateDialogOpen}
                title={"Create a new circle"}
                onOk={closeModal}
                closable={false}
                footer={null}
            >
                <CreateCircleForm circlesStore={store} onClose={closeModal} />
            </Modal>
            <Breadcrumb>
                <Breadcrumb.Item>Curator</Breadcrumb.Item>
                <Breadcrumb.Item>Your circles</Breadcrumb.Item>
            </Breadcrumb>
            <div className={styles.titleBar}>
                <h1 className={styles.pageTitle}>Circles you curate</h1>
            </div>
            <div className={styles.cardGrid}>
                <div className={styles.createCircleCard} onClick={openModal}>
                    <PlusOutlined />
                    <h4>Create a circle</h4>
                </div>
                {store.circles.map((c, i) => (
                    <motion.div
                        key={c.id}
                        initial={{ opacity: "0", y: `${-20 - 20 * i}px` }}
                        animate={{ y: "0px", opacity: "1" }}
                    >
                        <CircleCard circle={c} onClick={redirectToCircle(c.id)} />
                    </motion.div>
                ))}
            </div>
        </div>
    );
});
