import { RouteComponentProps, Redirect, Route, Link } from "react-router-dom";
import {AppStore, UiStore} from "../stores/appStore";
import React from "react";
import { Circles } from "./circles";
import { EditionEditor } from "./editionEditor";
import { Circle } from "./circle";
import {Newsletter, NewsletterWrapper} from "./newsletter";
import { Helmet } from "react-helmet";
import { Logo } from "../common/logo";
import { SwitchWithStrip } from "../common/switchWithStrip";
import { Avatar } from "antd";
import { observer } from "mobx-react";
import { Spinner } from "../components/loading/loading";
import styles from "./curator.module.scss";
import { Button } from "../common/common";
import { LoginLogout } from "@streamlinehq/streamline-regular/lib/interface-essential";


interface IUrlParams {}

interface IProps extends RouteComponentProps<IUrlParams> {
    appStore: AppStore;
}

interface ILogoBadgeProps {
    uiStore: UiStore;

}

const LogoBadge: React.FC<ILogoBadgeProps> = observer(({ uiStore }: ILogoBadgeProps) => {
    return (
        <div className={styles.logoBadge}>
            <Link className={styles.badge} to={"/"}>
                {uiStore.isLoading ? (
                    <Spinner color={"white"} size={"40px"} />
                ) : (
                    <Logo
                        withText={false}
                        color={"white"}
                        style={{ display: "flex", alignItems: "center" }}
                        width={"35px"}
                    />
                )}
            </Link>
        </div>
    );
});



export const Curator: React.FC<IProps> = ({ match, appStore }: IProps) => {
    if (appStore.currentUser == null) {
        return <Redirect to={"/login"} />;
    }

    const editionEditor = (match: RouteComponentProps<{ circleId: string; editionId: string }>) => (
        <EditionEditor appStore={appStore} {...match} />
    );
    const circle = (match: RouteComponentProps<{ circleId: string }>) => <Circle appStore={appStore} {...match} />;
    const editionPreview = (match: RouteComponentProps<{ circleId: string; editionId: string }>) => (
        <NewsletterWrapper preview={true} appStore={appStore} {...match} />
    );

    const userAccount = (
        <Link to={"/account"} className={styles.userAccount}>
            <Avatar className={styles.userAvatar} />
            Ethan Bond
        </Link>
    );

    return (
        <div className={styles.curatorView}>
            <Helmet>
                <title>Curate | slowpost</title>
            </Helmet>
            <div className={styles.sideBar}>
                <LogoBadge uiStore={appStore.uiStore} />
                <Link to={"/logout"}>
                    <Button icon={LoginLogout.Logout2} onClick={appStore.signOutUser} />
                </Link>
            </div>
            <div className={styles.contentArea}>
                <SwitchWithStrip>
                    <Route
                        path={`${match.path}/circles/:circleId/editions/:editionId/preview`}
                        component={editionPreview}
                    />
                    <Route path={`${match.path}/circles/:circleId/editions/:editionId`} component={editionEditor} />
                    <Route path={`${match.path}/circles/:circleId`} component={circle} />
                    <Route path={`${match.path}/circles`}>
                        <Circles appStore={appStore} />
                    </Route>
                    <Route path={`${match.path}/account`}>Account</Route>
                    <Route path={`${match.path}/`}>
                        <Redirect to={`${match.path}/circles`} />
                    </Route>
                </SwitchWithStrip>
            </div>
        </div>
    );
};
