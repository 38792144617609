import React from "react";
import { observer } from "mobx-react";
import { Tag, Skeleton } from "antd";
import { COLORS } from "../../common/utils";
import styles from "./circleCard.module.scss";
import {ICircle} from "../../types";
const cn = require("classnames");

interface ICircleCardProps {
    circle?: ICircle;
    onClick?: () => void;
}

export const CircleCard: React.FC<ICircleCardProps> = observer(({ onClick, circle }: ICircleCardProps) => {
    if (!circle) {
        return (
            <div className={styles.circleCard}>
                <Skeleton
                    active
                    title={{ style: { marginLeft: "12%", textAlign: "center" }, width: "76%" }}
                    className={styles.loadingCircle}
                    paragraph={false}
                >
                    Loading...
                </Skeleton>
            </div>
        );
    }
    return (
        <div className={cn(styles.circleCard, {[styles.interactive]: onClick})} onClick={onClick}>
            <div className={styles.circleName}>
                {circle.name}
            </div>
            <div className={ styles.circleStatus }>
                <Tag style={{ marginRight: "0" }} color={COLORS.ctaColor}>Survey open</Tag>
            </div>
        </div>
    );
});
