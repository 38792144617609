import React from "react";
import { Button, Input, message, Tag, Upload } from "antd";
import styles from "./questionList.module.scss";
import { QuestionStore, QuestionType } from "../../stores/questionStore";
import { observer } from "mobx-react";
import { ResponseFormStore } from "../../stores/responseFormStore";
import { CheckOutlined } from "@ant-design/icons";
import {CloseCircleOutlined, CloudUploadOutlined} from "@ant-design/icons/lib";

interface IProps {
    store: ResponseFormStore;
}

export const QuestionList: React.FC<IProps> = observer(({ store }: IProps) => {
    if (store.questions === undefined) {
        return <div>Loading...</div>;
    }

    const onFocusItem = (questionId: number | undefined) => () => {
        store.setFocusedQuestion(questionId);
    };

    const renderQuestionListItem = (question: QuestionStore, index: number) => {
        let color: string;
        let typeName: string;

        switch (question.type) {
            case QuestionType.Short:
                color = "red";
                typeName = "Short form";
                break;
            case QuestionType.Long:
                color = "purple";
                typeName = "Long form";
                break;
            case QuestionType.Image:
                color = "blue";
                typeName = "Image";
                break;
        }
        const isAnswered = question.answer !== undefined;
        const tickIcon = <CheckOutlined className={styles.answerCheck} style={{ color: "white" }} />;
        return (
            <div key={question.id} className={styles.questionListItem} onClick={onFocusItem(question.id)}>
                <div className={styles.questionContent} style={{ opacity: isAnswered ? 0.5 : 1 }}>
                    {question.prompt}
                </div>
                {isAnswered ? (
                    <Tag color={"#05C39A"} icon={tickIcon}>
                        Answered!
                    </Tag>
                ) : (
                    <Tag color={color}>{typeName}</Tag>
                )}
            </div>
        );
    };

    const renderFocusedQuestion = (question: QuestionStore) => {
        const handleSetAnswer = (e: React.FormEvent<HTMLTextAreaElement>) => {
            question.setAnswer(e.currentTarget.value);
        };

        const handleSetFile = (e: React.FormEvent<HTMLInputElement>) => {
            const { files } = e.currentTarget;
            if (!files || files.length === 0) {
                message.error("File upload failed... please make sure you are selecting a single image");
                return;
            }

            question.setFile(files[0]);
        };

        let inputEl: JSX.Element;

        if (question.type === QuestionType.Image) {
            const clearFile = () => question.setFile(undefined);
            const clearButton = <Button style={{ marginLeft: "0.5rem" }} icon={<CloseCircleOutlined />} onClick={clearFile} />;
            inputEl = (
                <div className={styles.imageUploadContainer}>
                    <div className={styles.imageUploadActions}>
                        <label className={styles.imageUploadButton}>
                            {question.file ? (
                                <div>
                                    <CheckOutlined style={{ marginRight: "0.5rem" }} />
                                    Got it!
                                </div>
                            ) : (
                                <div>
                                    <CloudUploadOutlined style={{ marginRight: "0.5rem" }} />
                                    Upload an image
                                </div>
                            )}
                            <input type={"file"} id={"answer"} name={"answer"} onChange={handleSetFile} />
                        </label>
                        {question.file ? clearButton : null}
                    </div>
                    <Input.TextArea
                        className={styles.imageDescriptionInput}
                        value={question.answer}
                        onChange={handleSetAnswer}
                        placeholder={"Add a description..."}
                    />
                </div>
            );
        } else {
            inputEl = (
                <Input.TextArea className={styles.answerInput} value={question.answer} onChange={handleSetAnswer} />
            );
        }

        return (
            <div className={styles.focusedQuestion}>
                <h3 className={styles.focusedQuestionContent}>{question.prompt}</h3>
                {inputEl}
            </div>
        );
    };

    if (store.focusedQuestion !== undefined) {
        return <div className={styles.questionList}>{renderFocusedQuestion(store.focusedQuestion)}</div>;
    }

    return <div className={styles.questionList}>{store.questions.map(renderQuestionListItem)}</div>;
});
